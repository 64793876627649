import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UpdateService {

  pageContent: Observable<any>;

  constructor(private http: HttpClient) {
    this.pageContent = this.getContentFile();
  }

  public getContentFile(): Observable<any> {
    return this.http.get('/assets/arquivo-atualizacao.json');
  }
}
