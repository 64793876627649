import {Component, OnInit} from '@angular/core';
import {LoaderService} from './shared/services/loader.service';
import {UpdateService} from './shared/services/update.service';
import * as AOS from 'aos';
import {NavigationEnd, Router} from '@angular/router';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'site-forte';
  blockTime = true;
  pageContent: any;

  constructor(
    public loaderService: LoaderService,
    public router: Router,
    private metaService: Meta,
  public updateService: UpdateService) {
    setTimeout( () => this.blockTime = false, 500);
    this.pageContent = this.updateService.pageContent;
  }

  ngOnInit() {
    AOS.init();
    const title = 'Gravação de Bateria Online com Cristiano Forte';
    const desc = 'Proposta excelente para compositores, instrumentistas, cantores, bandas, Home Studios e ' +
      'produtores que buscam praticidade para gravar as baterias em suas produções musicais.';
    this.metaService.addTag( { property: 'og:image', content: 'thumb.jpeg' });
    this.metaService.addTag( { property: 'og:title', content: title });
    this.metaService.addTag( { property: 'og:description', content: desc });
    if (window.location.pathname === '/') {
      this.router.navigate(['home']);
    }
  }
}
