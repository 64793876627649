import { NgModule } from '@angular/core';

import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTreeModule,
  MatInputModule, MatTabsModule, MatSelectModule, MatDialogModule, MatDividerModule, MatRadioModule, MatCheckboxModule
} from '@angular/material';



@NgModule({
  declarations: [
  ],
  imports: [
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTreeModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  exports: [
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTreeModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule
  ]
})
export class MaterialModule { }
