import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UpdateService} from '../../shared/services/update.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  pageContent;

  constructor(
    public router: Router,
    public updateService: UpdateService
  ) {
    this.pageContent = this.updateService.pageContent;
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
}
