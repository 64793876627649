import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Track} from 'ngx-audio-player';
import {LoaderService} from '../../shared/services/loader.service';
import {Router} from '@angular/router';
import {UpdateService} from '../../shared/services/update.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  pageContent;
  safeURL;
  videoUrlIndex = 0;
  msaapDisplayTitle = false;
  msaapDisplayPlayList = false;
  videoPrincipal;
// Material Style Advance Audio Player Playlist
  msaapPlaylist: Track[] = [];
  urlList: any[] = [];

  constructor(
    public _sanitizer: DomSanitizer,
    public loaderService: LoaderService,
    public router: Router,
    public updateService: UpdateService
  ) {
    this.scrollToTop();
    this.pageContent = this.updateService.pageContent;
    this.updateService.pageContent.subscribe( data => {
      this.videoPrincipal = this._sanitizer.bypassSecurityTrustResourceUrl(data.main.video_principal);
      data.main.audios.faixas.forEach( faixa => this.msaapPlaylist.push({title: faixa.titulo, link: faixa.link}));
      this.msaapDisplayTitle = true;
      this.msaapDisplayPlayList = true;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(data.main['videos adicionais'][0]['link']);

      data.main['videos adicionais'].forEach( v => this.urlList.push(v.link));
      });
    }

  avancaVideo() {
    this.videoUrlIndex++;
    this.buildVideoIndex();
  }

  voltaVideo() {
    this.videoUrlIndex--;
    this.buildVideoIndex();
  }

  buildVideoIndex() {
    this.loaderService.display(true);
    if (this.videoUrlIndex > this.urlList.length - 1) {
      this.videoUrlIndex = 0;
    }
    if (this.videoUrlIndex < 0) {
      this.videoUrlIndex = this.urlList.length - 1;
    }
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlList[this.videoUrlIndex]);
    setTimeout( () => this.loaderService.display(false), 1000);
  }
  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
}
