import {Routes} from '@angular/router';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {DashboardComponent} from './layout/dashboard/dashboard.component';
import {GravacaoComponent} from './pages/gravacao/gravacao.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {TermosComponent} from './pages/termos/termos.component';

export const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'home', component: HomeComponent, children: [
      { path: '', pathMatch: 'full' , component:  DashboardComponent},
      { path: 'gravacao', pathMatch: 'full' , component:  GravacaoComponent}
    ]},
  { path: 'termos', component: TermosComponent},
  { path: '**', component: PageNotFoundComponent}
  ];

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
