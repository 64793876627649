import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UpdateService} from '../../shared/services/update.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  pageContent;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public updateService: UpdateService

  ) {
    this.pageContent = this.updateService.pageContent;
  }

  ngOnInit() {
  }
}
