import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UpdateService} from '../../shared/services/update.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-gravacao',
  templateUrl: './gravacao.component.html',
  styleUrls: ['./gravacao.component.scss'],
})
export class GravacaoComponent implements OnInit {
  pageContent;
  safeURL;
  videos_dinamica_gravacao;
  videos_segmento_trabalho;
  videos_depoimento;
  videos_dinamica_gravacao_safe = [];
  videos_segmento_trabalho_safe = [];
  videos_depoimento_safe = [];


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _sanitizer: DomSanitizer,
    public updateService: UpdateService
  ) {
    this.pageContent = this.updateService.pageContent;
    this.scrollToTop();
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log(val.url);
      }
    });
    this.pageContent.subscribe( content => {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(content['gravacao']['video_principal']);
      this.videos_dinamica_gravacao = content['gravacao']['videos_dinamica_gravacao'];
      this.videos_segmento_trabalho = content['gravacao']['videos_segmento_trabalho'];
      this.videos_depoimento = content['gravacao']['videos_depoimento'];
      this.videos_segmento_trabalho.forEach( v => {
        this.videos_segmento_trabalho_safe.push({titulo: v['titulo'], link: this._sanitizer.bypassSecurityTrustResourceUrl(v['link'])});
      });
      this.videos_dinamica_gravacao.forEach( v => {
        this.videos_dinamica_gravacao_safe.push({titulo: v['titulo'], link: this._sanitizer.bypassSecurityTrustResourceUrl(v['link'])});
      });
      this.videos_depoimento.forEach( v => {
        this.videos_depoimento_safe.push({titulo: v['titulo'], link: this._sanitizer.bypassSecurityTrustResourceUrl(v['link'])});
      });
      console.log(this.videos_segmento_trabalho_safe);
    });
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  getSafeUrl(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);

  }

}
