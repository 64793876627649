import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UpdateService} from '../../shared/services/update.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.scss'],
})
export class TermosComponent implements OnInit {
  pageContent;
  safeURL;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _sanitizer: DomSanitizer,
    public updateService: UpdateService
  ) {
    this.pageContent = this.updateService.pageContent;
    this.scrollToTop();
  }

  ngOnInit() {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/lUC4qPW0OzY');
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log(val.url);
      }
    });
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  getSafeUrl(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);

  }

}
