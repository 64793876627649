import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {MenuComponent} from './layout/menu/menu.component';
import {HomeComponent} from './pages/home/home.component';
import {DashboardComponent} from './layout/dashboard/dashboard.component';
import {appRoutes, AppRoutingModule} from './app.routing.module';
import {RouterModule} from '@angular/router';
import {NgxAudioPlayerModule} from 'ngx-audio-player';
import {MaterialModule} from './shared/material.module';
import {PrimeModule} from './shared/prime.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderService} from './shared/services/loader.service';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {GravacaoComponent} from './pages/gravacao/gravacao.component';
import {UpdateService} from './shared/services/update.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {TermosComponent} from './pages/termos/termos.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    DashboardComponent,
    GravacaoComponent,
    TermosComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PrimeModule,
    MaterialModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    AngularFireModule.initializeApp(environment.firebase),
    NgxAudioPlayerModule
  ],
  providers: [
    HttpClient,
    LoaderService,
    UpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
